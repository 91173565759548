import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";
import TopBar from '../../../components/TopBar'
import Footer from '../../../components/Footer'
import Intake1 from "../Intake1/index.js"
import GuniteIntake2 from "../GuniteIntake2";
import GuniteIntake3a from "../GuniteIntake3a";
import PlasterIntake3a from "../PlasterIntake3a/index.js";
import GuniteIntake3b from "../GuniteIntake3b";
import PlasterIntake3b from "../PlasterIntake3b/index.js";
import GuniteIntake3c from "../GuniteIntake3c";
import PlasterIntake3c from "../PlasterIntake3c/index.js";
import GuniteIntake3d from "../GuniteIntake3d/index.js";
import PlasterIntake3d from "../PlasterIntake3d/index.js";
import Intake4 from "../Intake4/index.js";
import GuniteIntake5 from "../GuniteIntake5/index.js";
import PlasterIntake5 from "../PlasterIntake5/index.js";
import RequestSubmitted from '../RequestSubmitted/index.js';
import ChooseLocation from "../ChooseLocation/index.js";

const IntakeLayout = () => {
  return (
    <div>
      <TopBar />
      <Routes>
          <Route exact={true} path='/gunite/intake-1' element={<Intake1 />} />
          <Route exact={true} path='/plaster/intake-1' element={<Intake1 />} />
          <Route exact={true} path='/gunite/intake-2' element={<GuniteIntake2 />} />
          <Route exact={true} path='/gunite/intake-3a' element={<GuniteIntake3a />} />
          <Route exact={true} path='/plaster/intake-3a' element={<PlasterIntake3a />} />
          <Route exact={true} path='/gunite/intake-3b' element={<GuniteIntake3b />} />
          <Route exact={true} path='/plaster/intake-3b' element={<PlasterIntake3b />} />
          <Route exact={true} path='/gunite/intake-3c' element={<GuniteIntake3c />} />
          <Route exact={true} path='/plaster/intake-3c' element={<PlasterIntake3c />} />
          <Route exact={true} path='/gunite/intake-3d' element={<GuniteIntake3d />} />
          <Route exact={true} path='/plaster/intake-3d' element={<PlasterIntake3d />} />
          <Route exact={true} path='/gunite/intake-4' element={<Intake4 />} />
          <Route exact={true} path='/plaster/intake-4' element={<Intake4 />} />
          <Route exact={true} path='/gunite/intake-5' element={<GuniteIntake5 />} />
          <Route exact={true} path='/plaster/intake-5' element={<PlasterIntake5 />} />
          <Route exact={true} path='/request-submitted' element={<RequestSubmitted />} />
          <Route exact={true} path='/gunite/choose-location' element={<ChooseLocation />} />
          <Route exact={true} path='/plaster/choose-location' element={<ChooseLocation />} />
          <Route exact={true} path='/' element={<Navigate to="/" />} />
        </Routes>
      <Footer />
    </div>
  )
}

export default IntakeLayout
