import React, { createContext, useState, useEffect, useContext, useRef } from 'react';
import cookies from 'browser-cookies'
import { useLocation, useNavigate } from 'react-router-dom';
import serviceMethods from '../service'
import { useAuth } from './auth'
import { useIntakeForm } from './intakeForm';

export const ProgressContext = createContext();

let hasFetchedPathway = false

const ProgressProvider = (props) => {
  const { setJobDetails, setPoolDetails, DEFAULT_JOB, DEFAULT_POOL } = useIntakeForm()
  const { pathname } = useLocation();
  let navigate = useNavigate()
  const [pathway, setPathway] = useState(null)
  const [isFetchingPathway, setIsFetchingPathway] = useState(false)
  const [currentPosition, setCurrentPosition] = useState(null) //integer
  const [currentService, setCurrentService] = useState('') //will be either 'gunite' or 'plaster'
  const [skipASRChoice, setSkipASRChoice] = useState(false);
  const { isLoggedIn, hasFetchedProfile, me } = useAuth()

  const pathnameList = useRef([])

  const redirectToLoginRoutes = [
    '/foreman',
    '/scheduler',
    '/admin',
    '/branchadmin',
    '/crew',
    '/driver'
  ]
  const isProtectedRoute = redirectToLoginRoutes.some(route => pathname.includes(route));


  const loggedInLandingRoutes = {
    'SCHEDULER': '/scheduler/planner',
    'CUSTOMER': '/customer/myjobs',
    'ADMIN': '/admin/planner',
    'BRANCHADMIN': '/branchadmin/planner',

    //VIEWS NOT YET BUILT
    'FOREMAN': '/foreman/daily-job-log',
    'CREWMEMBER': '/crew/account',
    'DRIVER': '/driver/account',
  }

  useEffect(()=>{
    const regex = /\/([^/]+)\//;
    const match = pathname.match(regex)

    if (!isLoggedIn && isProtectedRoute && hasFetchedProfile){
      navigate('/login')
    } else if (isLoggedIn && me?.role && match &&
      ((match[1].includes('scheduler') && me.role !== 'SCHEDULER')
      || (!match[1].includes('branch') && match[1].includes('admin') && me.role !== 'ADMIN')
      || (match[1].includes('branchadmin') && me.role !== 'BRANCHADMIN')
      || (match[1].includes('customer') && me.role !== 'CUSTOMER')
      || (match[1].includes('foreman') && me.role !== 'FOREMAN')
      || (match[1].includes('crew') && me.role !== 'CREWMEMBER')
      || (match[1].includes('driver') && me.role !== 'DRIVER'))
    ) {
      navigate(loggedInLandingRoutes[me?.role])
    }
  },[pathname, hasFetchedProfile, me?.role])

  const getPathwayAsync = async () => {
    hasFetchedPathway = true
    setIsFetchingPathway(true)
    const res = await serviceMethods.getPathway()
    if (res.ok) {
      //STEP 2
      const newPathway = await res.json()
      setPathway(newPathway)
      setIsFetchingPathway(false)
    } else {
      hasFetchedPathway = false
    }
  }

  //STEP 1
  useEffect(() => {
    if (pathway === null && !isFetchingPathway && !hasFetchedPathway) {
      getPathwayAsync()
    }
  }, [pathway, isFetchingPathway])


  useEffect(()=>{
    if (pathname.includes('gunite') && currentService !== 'gunite') {
      setCurrentService('gunite')
    } else if (pathname.includes('plaster') && currentService !== 'plaster') {
      setCurrentService('plaster')
    }

    if (pathname.includes('plaster') && skipASRChoice) {
      setSkipASRChoice(false)
    }

    pathnameList.current.unshift(pathname)

  },[currentService, pathname])

  const customerRoutes = [
    '/customer/account',
    '/customer/drafts',
    '/customer/myjobs'
  ]

  useEffect(() => {
    if (!isFetchingPathway
      && pathway !== null
      && currentService
      ) {
      const publicRoutes = [
        '/',
        '/intake-form/gunite/choose-location',
        '/intake-form/plaster/choose-location',
        '/intake-form/gunite/intake-1',
        '/intake-form/plaster/intake-1'
      ]
      cookies.set('currentService', currentService)
      const lastLocation = pathway[currentService][currentPosition]
      const cookiePosition = parseInt(cookies.get('currentPosition'))
      if (
        hasFetchedProfile
        && !isLoggedIn
        && !publicRoutes.includes(pathname)
      ) {
        navigate('/')
        cookies.erase('currentPosition')
      } else if (
        hasFetchedProfile
        && isLoggedIn
        && currentPosition !== null
        && !customerRoutes.includes(pathname)
      ) {
        if (currentPosition !== cookiePosition) {
          cookies.set('currentPosition', `${currentPosition}`)
        } else if (
          lastLocation
          && pathname !== lastLocation
        ) {
          // navigate(lastLocation)
          // prevent users from manually entering a future step into the address bar
          // without breaking browser back / forward buttons
          // good luck
        }
      } else if (currentPosition === null && cookiePosition > -1) {
        setCurrentPosition(cookiePosition)
      }
    }
  },[currentPosition, pathname, pathway, currentService, isFetchingPathway, isLoggedIn, hasFetchedProfile])

  useEffect(()=>{
    if (customerRoutes.includes(pathname) && !isLoggedIn && hasFetchedProfile) {
      //STEP 3
      navigate('/login')
      cookies.erase('currentPosition')
    }
  },[hasFetchedProfile])

  const startGunite = () => {
    cookies.erase('intakeFlowId');
    cookies.erase('currentPosition');
    cookies.erase('currentService');
    setJobDetails([DEFAULT_JOB])
    setPoolDetails([DEFAULT_POOL])
    window.scrollTo(0, 0)
    setCurrentService('gunite')
    cookies.set('currentPosition', '0')
    setCurrentPosition(0)
    const newPathname = pathway.gunite[0]
    navigate(newPathname)
  }

  const startPlaster = () => {
    cookies.erase('intakeFlowId');
    cookies.erase('currentPosition');
    cookies.erase('currentService');
    setJobDetails([DEFAULT_JOB])
    setPoolDetails([DEFAULT_POOL])
    window.scrollTo(0, 0)
    setCurrentService('plaster')
    cookies.set('currentPosition', '0')
    setCurrentPosition(0)
    const newPathname = pathway.plaster[0]
    navigate(newPathname)
  }

  const navigateNext = () => {
    if (currentService) {
      window.scrollTo(0, 0)

      let newPosition = currentPosition
      if (currentPosition === 0) {
        if (isLoggedIn) {
          newPosition += 1
          if (skipASRChoice) {
            newPosition += 1
          }
        }
      } else if (currentPosition === 1) {
        if (skipASRChoice) {
          newPosition += 1
        }
      }
      newPosition += 1

      const newPathname = pathway[currentService][newPosition]
      cookies.set('currentPosition', `${newPosition}`)
      setCurrentPosition(newPosition)
      navigate(newPathname)
    }
  }

  //BELOW HANDLES THE RED BACK BUTTON IN APP NOT BROWSER
  const navigatePrevious = () => {
    if (customerRoutes.includes(pathname)){
      const newPosition = parseInt(cookies.get('currentPosition')) || 0;
      const newPathname = pathway[currentService][newPosition];
      setCurrentPosition(newPosition);
      navigate(newPathname);
    } else if (pathname.includes('/intake-3a') && skipASRChoice){
      if (pathname.includes('gunite')){
        window.scrollTo(0, 0)
        const newPosition = Math.max(currentPosition - 3, 0);
        const newPathname = pathway[currentService][newPosition];
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition);
        navigate(newPathname);
      } else {
        window.scrollTo(0, 0)
        const newPosition = Math.max(currentPosition - 2, 0);
        const newPathname = pathway[currentService][newPosition];
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition);
        navigate(newPathname);
      }
    } else if (pathname.includes('gunite/intake-3a') && !skipASRChoice){
      window.scrollTo(0, 0)
      const newPosition = Math.max(currentPosition - 1, 0);
      const newPathname = pathway[currentService][newPosition];
      cookies.set('currentPosition', `${newPosition}`);
      setCurrentPosition(newPosition);
      navigate(newPathname);
    } else if (pathname.includes('gunite/intake-2')) {
      window.scrollTo(0, 0)
      const newPosition = Math.max(currentPosition - 2, 0);
      const newPathname = pathway[currentService][newPosition];
      cookies.set('currentPosition', `${newPosition}`);
      setCurrentPosition(newPosition);
      navigate(newPathname);
    } else if (customerRoutes.includes(pathname)){
      window.scrollTo(0, 0)
      const newPosition = cookies.get('currentPosition');
      const newPathname = pathway[currentService][newPosition];
      setCurrentPosition(newPosition);
      navigate(newPathname);
    } else {
      if (!customerRoutes.includes(pathname) && currentPosition > 0){
        window.scrollTo(0, 0)
        const newPosition = currentPosition - 1;
        const newPathname = pathway[currentService][newPosition];
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition);
        navigate(newPathname);
      } else {
        const newPosition = parseInt(cookies.get('currentPosition'));
        const newPathname = pathway[currentService][newPosition];
        setCurrentPosition(newPosition);
        navigate(newPathname);
      }
    }
  }

  const navigateToStart = () => {
    window.scrollTo(0, 0)
    setCurrentService('')
    cookies.erase('currentPosition')
    setCurrentPosition(null)
    navigate('/')
  }

  //BELOW HANDLES BROWSER BACK BUTTON
  const [currentPositionUpdated, setCurrentPositionUpdated] = useState(false)
  useEffect(()=>{
    const handlePopState = () => {
      let updatedCurrentPositionCookie = Number(cookies.get('currentPosition'))
      setCurrentPosition(updatedCurrentPositionCookie);
      setCurrentPositionUpdated(!currentPositionUpdated);
    };
    window.addEventListener('popstate', handlePopState);

    if (pathnameList.current[1]?.includes('customer')){
      // do nothing
    } else if (pathname.includes('/intake-3a')) {
      if (pathname.includes('gunite')){
        window.scrollTo(0, 0)
        const newPosition = 3;
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition);
      } else {
        window.scrollTo(0, 0)
        const newPosition = 2;
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition);
      }
    } else if (pathname.includes('gunite/intake-2')) {
      window.scrollTo(0, 0)
      const newPosition = 2;
      cookies.set('currentPosition', `${newPosition}`);
      setCurrentPosition(newPosition);
    } else if (pathname.includes('intake-1')) {
      window.scrollTo(0, 0)
      const newPosition = 1;
      cookies.set('currentPosition', `${newPosition}`);
      setCurrentPosition(newPosition);
    } else if (pathname.includes('choose-location')) {
      window.scrollTo(0, 0)
      const newPosition = 0;
      cookies.set('currentPosition', `${newPosition}`);
      setCurrentPosition(newPosition);
    } else { //Any step past 3a
      window.scrollTo(0, 0)
      if (currentPosition > 0){
        const newPosition = currentPosition - 1;
        cookies.set('currentPosition', `${newPosition}`);
        setCurrentPosition(newPosition)
      }
    }

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  },[currentPositionUpdated]);

  const stepDisplayTextGunite = {
    urls: skipASRChoice
      ? [
        '/',
        '/intake-form/gunite/intake-1',
        ['/intake-form/gunite/intake-3a', '/intake-form/gunite/intake-3b', '/intake-form/gunite/intake-3c', '/intake-form/gunite/intake-3d'],
        '/intake-form/gunite/intake-4',
        '/intake-form/gunite/intake-5',
      ] :
      [
        '/',
        '/intake-form/gunite/intake-1',
        '/intake-form/gunite/intake-2',
        ['/intake-form/gunite/intake-3a', '/intake-form/gunite/intake-3b', '/intake-form/gunite/intake-3c', '/intake-form/gunite/intake-3d'],
        '/intake-form/gunite/intake-4',
        '/intake-form/gunite/intake-5',
      ],
    header: skipASRChoice
      ? [
        'Gunite & Plaster Schedule Request',
        'Builder Information',
        'Job site information',
        'Schedule',
        'Summary',
      ] :
      [
        'Gunite & Plaster Schedule Request',
        'Builder Information',
        'Choose gunite mix',
        'Job site information',
        'Schedule',
        'Summary',
      ],
    sub: skipASRChoice
      ? [
        'Choose the service you want to schedule',
        'Contact Information for your Business',
        'All the information about the job site, address, pool plan, job site supervisor.',
        '',
        '',
      ] :
      [
        'Choose the service you want to schedule',
        'Contact Information for your Business',
        '',
        'All the information about the job site, address, pool plan, job site supervisor.',
        '',
        '',
      ],
    jobSiteHeaders: [
      'Address',
      'Pool plan',
      'Plaster',
      'Supervisor',
    ],
  };

  const stepDisplayTextPlaster = {
    urls: [
      '/',
      '/intake-form/plaster/intake-1',
      ['/intake-form/plaster/intake-3a', '/intake-form/plaster/intake-3b', '/intake-form/plaster/intake-3c', '/intake-form/plaster/intake-3d'],
      '/intake-form/plaster/intake-4',
      '/intake-form/plaster/intake-5',
    ],
    header: [
      'Gunite & Plaster Schedule Request',
      'Builder Information',
      'Job site information',
      'Schedule',
      'Summary',
    ],
    sub: [
      'Choose the service you want to schedule',
      'Contact Information for your Business',
      'All the information about the job site, address, pool plan, job site supervisor.',
      '',
      '',
    ],
    jobSiteHeaders: [
      'Address',
      'Pool plan',
      'Plaster',
      'Supervisor',
    ]
  };

  const currentStepGunite = stepDisplayTextGunite.urls.findIndex((value) => {
    if (Array.isArray(value)) {
      return value.includes(pathname);
    } else {
      return value === pathname;
    }
  });
  const currentStepPlaster = stepDisplayTextPlaster.urls.findIndex((value) => {
    if (Array.isArray(value)) {
      return value.includes(pathname);
    } else {
      return value === pathname;
    }
  });

  const currentJobSiteStepGunite =
  skipASRChoice
    ? (Array.isArray(stepDisplayTextGunite.urls[2])
        ? stepDisplayTextGunite.urls[2].findIndex(value => value === pathname) + 1
        : "")
    : (Array.isArray(stepDisplayTextGunite.urls[3])
        ? stepDisplayTextGunite.urls[3].findIndex(value => value === pathname) + 1
        : "");
  const currentJobSiteStepPlaster = stepDisplayTextPlaster.urls[2].findIndex(value => value === pathname) + 1;
  const currentHeaderGunite = stepDisplayTextGunite.header[currentStepGunite];
  const currentHeaderPlaster = stepDisplayTextPlaster.header[currentStepPlaster];
  const currentSubTextGunite = stepDisplayTextGunite.sub[currentStepGunite];
  const currentSubTextPlaster = stepDisplayTextPlaster.sub[currentStepPlaster];
  const [zipCode, setZipCode] = useState('');

  useEffect(()=>{
    const { searchZip } = serviceMethods.getLocationCookies()
    if (searchZip && zipCode !== searchZip) {
      setZipCode(searchZip)
    }
  },[])

  const startIntakeFlow = (intakeFlowId) => {
    cookies.set('intakeFlowId', intakeFlowId.toString())
    const newCurrentJobs = me.jobs.filter(job => job.intakeFlowId === intakeFlowId)
    setJobDetails(newCurrentJobs)
    cookies.set('currentService', newCurrentJobs[0].jobType)
    setCurrentService(newCurrentJobs[0].jobType)
    cookies.erase('guniteMixType')
    if (newCurrentJobs[0].jobType === 'gunite') {
      if (newCurrentJobs[0].guniteType) {
        cookies.set('guniteMixType', newCurrentJobs[0].guniteType)
      }
      if (newCurrentJobs[0].locationId === 4 || newCurrentJobs[0].locationId === 8 || newCurrentJobs[0].locationId === 3) {
        setSkipASRChoice(false)
      } else {
        setSkipASRChoice(true)
      }
    }
    const newCurrentPools = []
    for (const job of newCurrentJobs) {
      if (job.pools[0]?.id) {
        const pool = job.pools[0]
        newCurrentPools.push(pool)
      } else {
        newCurrentPools.push(DEFAULT_POOL)
      }
    }
    setPoolDetails(newCurrentPools)
    if (newCurrentJobs[0].jobType === 'gunite') {
      setCurrentPosition(3);
      cookies.set('currentPosition', '3');
    }
    if (newCurrentJobs[0].jobType === 'plaster') {
      setCurrentPosition(2);
      cookies.set('currentPosition', '2');
    }
    navigate(`/intake-form/${newCurrentJobs[0].jobType}/intake-3a`)
  }

  const value = {
    currentJobSiteStepGunite,
    currentJobSiteStepPlaster,
    stepDisplayTextGunite,
    stepDisplayTextPlaster,
    currentStepGunite,
    currentStepPlaster,
    currentHeaderGunite,
    currentHeaderPlaster,
    currentSubTextGunite,
    currentSubTextPlaster,
    currentPosition,
    setCurrentPosition,
    startGunite,
    startPlaster,
    navigateNext,
    navigatePrevious,
    navigateToStart,
    zipCode,
    setZipCode,
    skipASRChoice,
    setSkipASRChoice,
    currentService,
    setCurrentService,
    pathnameList,
    startIntakeFlow,
    pathway,
  };

  return (
    <ProgressContext.Provider
      value={value}>
      {props.children}
    </ProgressContext.Provider>
  )
}
const useProgress = () => useContext(ProgressContext)
export { useProgress }
export default ProgressProvider
