import React from 'react'

const JobSelector = ({todaysJobs, selectedCalendarJob, askIfContinue, language }) => {

  return (
    <select
      onChange={(e) => {
        askIfContinue(e.target.value, selectedCalendarJob)}}
      value={selectedCalendarJob?.id}
    >
    <option value={0}>{language === 'English' ? 'Select a job': 'Seleccione un trabajo'}</option>
    {todaysJobs.map((calendarJob,i) => {
      const foremanName = calendarJob?.crews?.length ? `${calendarJob.crews[0].crew.foreman.user.name} - ` : ''
      return <option value={calendarJob.id} key={`tj${i}`} >
          { foremanName }
          {calendarJob.calendarJobType === 'REGULAR' ? 'Shoot ' : 'Flash '}
          {calendarJob.calendarJobType === 'REGULAR' ? calendarJob.shift : calendarJob.fshift} - {calendarJob.job.jobName}
        </option>
    })}
    </select>
  )
}

export default JobSelector
